import { useParams } from "react-router-dom";

const blogContent = {
  "viral-tiktok-subway-heat": {
    title: "How a Viral TikTok Led to a Citywide Heat Study",
    date: "February 2025",
    content: `
      Last summer, I posted a TikTok showing a subway station at 104°F.
      The response? Over 1,700 comments from people sharing their own horror stories of sweltering commutes.
      
      This made me realize: if so many people experience this problem, why isn't there better data on it? 
      
      That question led me to start installing sensors in subway stations...
    `,
  },
};

export default function BlogPost() {
  const { slug } = useParams();
  const post = blogContent[slug as keyof typeof blogContent];

  if (!post) return <p>Post not found.</p>;

  return (
    <div className="max-w-3xl mx-auto p-6">
      <h1 className="text-3xl font-bold">{post.title}</h1>
      <p className="text-gray-600">{post.date}</p>
      <p className="mt-4 whitespace-pre-line">{post.content}</p>
    </div>
  );
}
