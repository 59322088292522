import React from "react";

const About: React.FC = () => {
  return (
    <div className="px-6 md:px-12 py-16 max-w-7xl mx-auto">
      <div className="text-center mb-16">
        <h1 className="text-5xl font-extrabold text-gray-900 leading-tight">
          New York Lab
        </h1>
        <p className="text-lg text-gray-600 mt-4">
          Exploring urban environments through technology, art, and research.
        </p>
      </div>
      <div className="max-w-2xl mx-auto space-y-6 text-gray-800 text-lg leading-relaxed">
        <p className="font-semibold">
          Why am I sweating profusely at 9 a.m. while waiting for my train at
          Union Square on a late summer day?
        </p>
        <p>
          I’m three floors underground. I didn’t know humidity like this could
          exist. There’s a fan on the ceiling blowing 120°F air onto me. The AC
          inside the train stalled in the station is expelling its own hot air
          onto the platform. Up into my face. I’m paying $3 for this?
        </p>
        <p>
          <strong>New York Lab</strong> was born from this shared, all-too-familiar experience of <em>why is it like this?</em>
        </p>
        <p>
          We’re a research lab that explores issues affecting New Yorkers
          through the lens of technology, art, and curiosity. Our mission is to
          use creative and novel methods to understand the systems around us
          and ask questions that don’t always have easy answers.
        </p>
        <p>
          Our first project focuses on the heat and humidity inside NYC subway
          stations during the summer months. While New Yorkers have shared
          countless stories and anecdotes about the unbearable conditions
          underground, there’s been no sustained, long-term effort to collect
          actual data. This is our attempt to fill that gap.
        </p>
        <p>
          By installing custom sensors in select high-traffic stations, we’re
          gathering concrete data about the heat and humidity levels commuters
          experience daily. What will we do with this data? We’re not entirely
          sure yet—and that’s the point. Whether it informs advocacy, inspires
          art, or sparks entirely new conversations, we believe that gathering
          and sharing this information is the first step toward understanding
          and addressing the systems we live in.
        </p>
        <p>
          <strong>New York Lab</strong> is a place to explore the questions we
          don’t always know how to answer.
        </p>
      </div>
    </div>
  );
};

export default About;
