import { useState, useEffect } from "react";

export default function FastCounter() {
  const [count, setCount] = useState<number>(100);

  useEffect(() => {
    const interval = setInterval(() => {
      setCount((prev) => (prev === 150 ? 100 : prev + 1))
    }, 900) // Adjust speed here (lower = faster)

    return () => clearInterval(interval);
  }, []);

  // Calculate red intensity based on the count value, transitioning quickly to red
  const redIntensity = Math.min(255, (count - 100) * 10); // Fast red intensity increase
  const textColor = `rgb(${255}, ${255 - redIntensity}, ${255 - redIntensity})`; // Transition from white to red
  
  // Apply a melting effect: scale and slight blur based on the count
  // const scale = 1 + (count - 100) * 0.02; // Scale up slightly as count increases
  const blur = count < 105 ? 0 : Math.min(5, (count - 100) * 0.08); // Apply blur to give a "melting" appearance

  return (
    <div className="mt-1">
      <span
        className="text-sm font-extralight text-gray-300 font-mono leading-none flex items-center"
        style={{
          color: textColor,
          // transform: `scale(${scale})`,
          filter: `blur(${blur}px)`,
        }}
      >
        {count.toString().padStart(2, '0')}°F
      </span>
    </div>
  )
}
