import React, { useRef, useEffect, useState } from 'react';
import * as d3 from 'd3';

type TemperatureData = {
  id: number;
  stationId: number;
  temperature: number;
  humidity: number;
  timestamp: string;
};

type LineGraphProps = {
  data: TemperatureData[];
};

const LineGraph: React.FC<LineGraphProps> = ({ data }) => {
  const svgRef = useRef<SVGSVGElement>(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(true);
    if (!data.length) return;

    // Set up dimensions and margins for phone and laptop
    const containerWidth = svgRef.current?.parentElement?.offsetWidth || 800;
    const containerHeight = containerWidth < 600 ? containerWidth * 0.8 : containerWidth * 0.4; // Double height for smaller screens
    const margin = { top: 20, right: 30, bottom: 50, left: 40 };
    const width = containerWidth - margin.left - margin.right;
    const height = containerHeight - margin.top - margin.bottom;

    // Parse the date and set up scales
    const parseDate = (dateString: string) => new Date(dateString);
    const xScale = d3.scaleTime()
      .domain(d3.extent(data, d => parseDate(d.timestamp)) as [Date, Date])
      .range([0, width]);

    const yScaleTemp = d3.scaleLinear()
      .domain([0, 100]) // Adjust according to your data range
      .range([height, 0]);

    // Line generator
    const lineTemperature = d3.line<TemperatureData>()
      .x(d => xScale(parseDate(d.timestamp) as Date))
      .y(d => yScaleTemp(d.temperature))
      .curve(d3.curveMonotoneX);

    // Append SVG and groups
    const svg = d3.select(svgRef.current)
      .attr('viewBox', `0 0 ${containerWidth} ${containerHeight}`)
      .attr('preserveAspectRatio', 'xMinYMin meet');

    svg.selectAll('*').remove(); // Clear previous render
    const g = svg.append('g').attr('transform', `translate(${margin.left},${margin.top})`);

    // Add temperature line
    g.append('path')
      .datum(data)
      .attr('fill', 'none')
      .attr('stroke', 'steelblue')
      .attr('stroke-width', 2) // Adjusted line width
      .attr('d', lineTemperature);

    const tickCount = containerWidth < 600 ? 4 : 6;

    // Add axes
    g.append('g')
      .attr('transform', `translate(0,${height})`)
      .call(d3.axisBottom(xScale).ticks(tickCount));

    g.append('g')
      .call(d3.axisLeft(yScaleTemp).ticks(5));

    // Add hover circle
    const hoverCircle = g.append('circle')
      .attr('r', 5)
      .attr('fill', 'orange')
      .style('display', 'none'); // Hide initially

    // Add tooltip
    const tooltip = d3.select((svg.node() as unknown as HTMLElement).parentElement!)
      .append('div')
      .style('position', 'absolute')
      .style('background', 'white')
      .style('padding', '5px')
      .style('border', '1px solid #ccc')
      .style('border-radius', '5px')
      .style('pointer-events', 'none')
      .style('display', 'none'); // Hide initially

    // Overlay for mouse events
    const overlay = g.append('rect')
      .attr('width', width)
      .attr('height', height)
      .attr('fill', 'none')
      .attr('pointer-events', 'all')
      .on('mousemove', function (event) {
        const [mouseX, mouseY] = d3.pointer(event);
        const date = xScale.invert(mouseX);

        // Find the closest data point
        const closestData = data.reduce((prev, curr) =>
          Math.abs(parseDate(curr.timestamp).getTime() - date.getTime()) <
          Math.abs(parseDate(prev.timestamp).getTime() - date.getTime())
            ? curr
            : prev
        );

        if (closestData) {
          const xPosition = xScale(parseDate(closestData.timestamp) as Date) + margin.left;
          const yPosition = yScaleTemp(closestData.temperature) + margin.top;

          // Adjust circle position and show it
          hoverCircle
            .attr('cx', xPosition - margin.left)
            .attr('cy', yPosition - margin.top)
            .style('display', 'block');

          // Format the timestamp for display
          const formattedTimestamp = new Date(closestData.timestamp).toLocaleString(
            'en-US',
            { dateStyle: 'short', timeStyle: 'short' } // Adjust format as needed
          );

          // Update tooltip with temperature data
          tooltip
            .html(
              `<strong>${formattedTimestamp}</strong><br>
               Temp: ${closestData.temperature}°F<br>
               Humidity: ${closestData.humidity}%`
            )
            .style('left', `${xPosition + 10}px`)
            .style('top', `${yPosition + 10}px`) // Adjust tooltip to avoid cursor overlap
            .style('display', 'block');
        }
      })
      .on('mouseout', () => {
        hoverCircle.style('display', 'none'); // Hide circle
        tooltip.style('display', 'none'); // Hide tooltip
      });

    setIsLoading(false);

    return () => {
      d3.select(svgRef.current).selectAll('*').remove();
      tooltip.remove();
    };
  }, [data]);

  return (
    <div
      className={`transition-all duration-500 ${
        isLoading ? 'opacity-0 scale-95' : 'opacity-100 scale-100'
      }`}
    >
      <svg ref={svgRef} />
    </div>
  );
};

export default LineGraph;
