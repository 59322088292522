import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import LineGraph from "../components/LineGraph";

type StationData = {
  id: number;
  name: string;
  stationSlug: string;
  latitude: number;
  longitude: number;
};

type TemperatureData = {
  id: number;
  stationId: number;
  temperature: number;
  humidity: number;
  timestamp: string;
};

type StationApiResponse = {
  station: StationData;
  temperatureData: TemperatureData[];
};

type StationParams = {
  station_slug: string;
};

const filterDataByTimeRange = (data: TemperatureData[], timeRange: string) => {
  const now = new Date();
  let filteredData = [];

  // Filter data based on the selected time range
  switch (timeRange) {
    case "1d": // 1 day
      const oneDayAgo = new Date(now.getTime() - 24 * 60 * 60 * 1000); // 24 hours ago
      filteredData = data.filter((d) => new Date(d.timestamp) >= oneDayAgo && new Date(d.timestamp).getMinutes() % 5 === 0);
      break;
    case "5d": // 5 days
      const fiveDaysAgo = new Date(now.getTime() - 5 * 24 * 60 * 60 * 1000); // 5 days ago
      filteredData = data.filter((d) => new Date(d.timestamp) >= fiveDaysAgo && new Date(d.timestamp).getMinutes() % 30 === 0);
      break;
    case "1m": // 1 month
      const oneMonthAgo = new Date(now.getTime() - 30 * 24 * 60 * 60 * 1000); // 30 days ago
      filteredData = data.filter((d) => new Date(d.timestamp) >= oneMonthAgo && new Date(d.timestamp).getMinutes() % 60 === 0);
      break;
    case "all": // All time
      filteredData = data;
      break;
    default:
      filteredData = data;
  }

  return filteredData;
};

const Station = (): JSX.Element => {
  const [stationData, setStationData] = useState<StationData | null>(null);
  // const [temperatureData, setTemperatureData] = useState<TemperatureData[]>([]);
  const [allTemperatureData, setAllTemperatureData] = useState<TemperatureData[]>([]); // Store all data
  const [filteredTemperatureData, setFilteredTemperatureData] = useState<TemperatureData[]>([]); // Filtered data for display
  const [currentTemperature, setCurrentTemperature] = useState<TemperatureData>();
  const [selectedTimeRange, setSelectedTimeRange] = useState("1d");
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState(true);
  const { stationSlug } = useParams();

  useEffect(() => {
    // Fetch the station data from the backend
    const fetchData = async () => {
      try {
        const response = await fetch(`/stations/${stationSlug}`);
        const data: StationApiResponse = await response.json();

        setStationData(data.station);
        setAllTemperatureData(data.temperatureData);
        setFilteredTemperatureData(
          filterDataByTimeRange(data.temperatureData, "1d")
        );
        setCurrentTemperature(data.temperatureData[0]);
      } catch (err: any) {
        setError(err.message);
      } finally {
        setLoading(false); // Data has finished loading
      }
    };

    fetchData();
  }, [stationSlug]);

  // Re-filter data when the selectedTimeRange changes
  useEffect(() => {
    if (allTemperatureData.length > 0) {
      setFilteredTemperatureData(filterDataByTimeRange(allTemperatureData, selectedTimeRange));
    }
  }, [selectedTimeRange, allTemperatureData]);

  return (
    <div className={`flex flex-col items-center justify-center min-h-screen space-y-8 transition-all duration-500 ${loading ? 'scale-95 opacity-0' : 'scale-100 opacity-100'}`}>
      {/* Temperature Data Display */}
      {stationData && currentTemperature && (
        <div className="w-full max-w-4xl space-y-6 text-center">
          <h2 className="text-2xl font-semibold">{stationData.name}</h2>
          
          {filteredTemperatureData.length > 0 ? (
            <div>
              <p className="text-lg">
                <strong>Current temperature:</strong> {currentTemperature.temperature}°F
              </p>
              <p className="text-lg">
                <strong>Current humidity:</strong> {currentTemperature.humidity}%
              </p>
            </div>
          ) : (
            <p className="text-center text-gray-500">Loading temperature data...</p>
          )}
  
          {/* Time Range Buttons */}
          <div className="flex justify-center space-x-4">
            {["1d", "5d", "1m", "all"].map((range) => (
              <button
                key={range}
                onClick={() => setSelectedTimeRange(range)}
                className={`px-4 py-2 text-sm font-medium rounded-lg ${
                  selectedTimeRange === range
                    ? "bg-blue-600 text-white"
                    : "bg-gray-100 text-gray-800 hover:bg-gray-200"
                }`}
              >
                {range === "1d" ? "1 Day" : range === "5d" ? "5 Days" : range === "1m" ? "1 Month" : "All Time"}
              </button>
            ))}
          </div>
        </div>
      )}
  
      {error && <div className="text-red-500">Error: {error}</div>}
  
      {/* Graph Display */}
      <div className="w-full max-w-[1000px] mx-auto p-4">
        <LineGraph data={filteredTemperatureData} />
      </div>
    </div>
  );
  
};

export default Station;
