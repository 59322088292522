// src/components/Sideview.tsx
import React, { useState } from "react";
import { Menu } from "lucide-react";

interface SideviewProps {
  // You can add props here if you want to customize the sideview
  // For example, passing content or titles as props
}

const Sideview: React.FC<SideviewProps> = () => {
  const [isSideviewOpen, setIsSideviewOpen] = useState(false);

  const toggleSideview = () => {
    setIsSideviewOpen(!isSideviewOpen); // Toggle sideview open/close
  };

  return (
    <div>
      {/* Menu button */}
      <button
        className="text-gray-300 font-extralight text-xl mx-1 leading-none flex pb-[2px]"
        onClick={toggleSideview}
      >
        <Menu size={24} />
      </button>

      {/* Sideview */}
      <div
        className={`fixed top-0 left-0 h-full bg-gray-100 text-white transition-all duration-300 ease-in-out transform ${
          isSideviewOpen ? "translate-x-0" : "-translate-x-full"
        } w-full md:w-72 z-20 overflow-y-auto`}
        style={{ overscrollBehavior: "contain" }} // Full width on small screens, 18rem on larger screens
      >
        {/* Close button inside the sideview */}
        <button
          className="absolute top-5 right-5 text-gray-600 text-2xl"
          onClick={toggleSideview}
        >
          ✕
        </button>

        <div className="p-6">
          <h2 className="font-syne-mono text-xl text-gray-600 pb-2">about</h2>
          {/* <p className="text-xs text-gray-600 pb-2">
            New York Lab explores issues affecting New Yorkers through
            the lens of technology, art, and curiosity.
          </p> */}
          <p className="text-xs text-gray-600 pb-2">
            Our goal is to use creative and novel methods to understand the
            systems around us and ask questions that don’t always have easy
            answers.
          </p>
          <p className="text-xs text-gray-600 pb-2">
            Inspired by a{" "}
            <a
              href="https://www.tiktok.com/@jackistryinghisverybest/video/7400747309769723166"
              target="_blank"
              rel="noopener noreferrer"
              className="text-blue-500 underline"
            >
              TikTok video
            </a>{" "}
            we made last August, our first project focuses on the heat and
            humidity inside NYC subway stations during the summer months. While
            New Yorkers have shared countless stories and anecdotes about the
            unbearable conditions underground, there’s been no sustained,
            long-term effort to collect actual data. This is our attempt to fill
            that gap.
          </p>
          <p className="text-xs text-gray-600 pb-2">
            By installing custom sensors in select high-traffic stations, we’re
            collecting concrete data about the heat and humidity levels commuters
            experience daily. Gathering and sharing this information moves us toward
            understanding and addressing the systems we live in.
          </p>
          {/* <p className="text-xs text-gray-600 pb-2">
          By installing custom sensors in select high-traffic stations, we’re
          gathering concrete data about the heat and humidity levels commuters
          experience daily. What will we do with this data? We’re not entirely
          sure yet—and that’s the point. Whether it informs advocacy, inspires
          art, or sparks entirely new conversations, we believe that gathering
          and sharing this information is the first step toward understanding
          and addressing the systems we live in.
        </p> */}
          <a
            href="https://www.instagram.com/newyorklab.co"
            target="_blank"
            rel="noopener noreferrer"
            className="text-xs text-gray-600 no-underline"
          >
            @newyorklab.co
          </a>
          <h2 className="font-syne-mono text-xl mt-4 text-gray-600 pb-2">contact</h2>
          <p className="text-xs text-gray-600">
            Feel free to reach out to <a className="text-blue-500" href="mailto:info@newyorklab.co">info@newyorklab.co</a> for any kind of collaboration!
          </p>
        </div>
      </div>
    </div>
  );
};

export default Sideview;
