// App.tsx
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
// import Home from './pages/Home';
// import Station from './pages/StationOld';
import Station from "./pages/Station";
import About from "./pages/About";
import Blog from "./pages/Blog";
import BlogPost from "./pages/blog/[slug]";
import Home from "./pages/Home";
import SplashPage from "./pages/SplashPage";

const App = (): JSX.Element => {
  return (
    <Router>
      <Routes>
        {/* <Route path="/" element={<Home />} /> */}
        <Route path="/" element={<SplashPage />} />
        <Route path="/station/:stationSlug" element={<Station />} />
        <Route path="/about" element={<About />} />
        <Route path="/blog" element={<Blog />} />
        <Route path="/blog/:slug" element={<BlogPost />} />
      </Routes>
    </Router>
  );
};

export default App;
