import { useEffect, useState } from "react";
import Sideview from "../components/Sideview";
import FastCounter from "../components/FastCounter";

const SplashPage = (): JSX.Element => {
  const [fadeIn, setFadeIn] = useState(false);

  useEffect(() => {
    // Set fadeIn to true after the component mounts, triggering the fade-in effect
    setFadeIn(true);
  }, []);

  return (
    <div
      className={`transition-opacity duration-1000 ${
        fadeIn ? "opacity-100" : "opacity-0"
      }`}
    >
      <video
        className="absolute top-0 left-0 w-full h-screen object-cover"
        autoPlay
        loop
        muted
        playsInline
      >
        <source
          src="https://eraxftnbodkbhmdl.public.blob.vercel-storage.com/hotnyclowres-EwE7jZZeLfwslGYtqjiIpYjT9K7KVO.mov"
          type="video/mp4"
        />
        {/* <source
          src="https://eraxftnbodkbhmdl.public.blob.vercel-storage.com/trainclip3-vjm6uH0SviMkCBwgObdIjXYUAdHk4d.mov"
          type="video/mp4"
        /> */}
        {/* <source
          src="https://eraxftnbodkbhmdl.public.blob.vercel-storage.com/background-p8CCMf6KEx1zkyhStkCIRMGk2qOTp7.mov"
          type="video/mp4"
        /> */}
      </video>
      <div className="fixed z-10 w-full">
        <div className="flex flex-row pl-6">
          <h1 className="font-syne-mono mr-[15px] no-underline text-white/90 pt-[18px] text-2xl font-medium">
            <strong>New York</strong> Lab
          </h1>
          <div className="flex items-end pb-[2px] ml-auto pr-6 pt-1">
            <Sideview />
          </div>
        </div>
        <div className="flex pl-6 pb-3">
          <FastCounter />
        </div>
        <p className="text-white/90 text-sm pl-6">
          Exploring issues affecting New Yorkers through the lens of technology, art, and curiosity.
        </p>
        {/* <Link to="/about" style={{ textDecoration: 'none', color: '#007bff' }}>
            About
            </Link> */}
          <p className="text-xs px-6 my-5 text-white max-w-xl">
            For our first project, we’re installing sensors in train stations across the city to gather real
            data on heat and humidity underground. Whether this data fuels
            advocacy, sparks art, or starts new conversations, we believe the
            first step is making it public.
          </p>
          {/* <a
            href="your-signup-link"
            className="bg-white text-black font-semibold py-2 px-4 rounded-lg hover:bg-gray-200 transition"
          >
            Sign up to follow the project →
          </a> */}
        {/* <p className="text-xs px-6 my-5 text-white max-w-xl mx-auto">
          We’re a research lab exploring issues affecting New Yorkers through
          the lens of technology, art, and curiosity. Our mission is to use
          creative and novel methods to understand the systems around us and ask
          questions that don’t always have easy answers.
        </p>
        <p className="text-xs pl-9">
          Our first project focuses on train station heat. More to come.
        </p> */}
      </div>
      {/* <div className="relative z-10 flex">
        <h1 className="text-white">New York Lab</h1>
      </div> */}
      {/* <div className="flex py-16 px-9 max-w-2xl md:px-12 items-center justify-center w-full">
        <motion.h1
          className="text-white text-4xl font-bold"
          animate={{ rotateY: [0, 360] }}
          transition={{ repeat: Infinity, duration: 30, ease: "linear" }}
        >
          New York Lab
        </motion.h1>
         */}
    </div>
  );
};

export default SplashPage;
