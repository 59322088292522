import { Link } from "react-router-dom";

const blogPosts = [
  {
    title: "How a Viral TikTok Led to a Citywide Heat Study",
    date: "February 2025",
    excerpt:
      "A simple video with a thermometer sparked a movement to measure NYC’s subway heat...",
    slug: "viral-tiktok-subway-heat",
  },
];

export default function Blog() {
  return (
    <div className="max-w-3xl mx-auto p-6">
      <h1 className="text-3xl font-bold mb-4">Blog</h1>
      {blogPosts.map((post) => (
        <div key={post.slug} className="mb-6">
          <h2 className="text-2xl font-semibold">
            <Link to={`/blog/${post.slug}`} className="hover:underline">
              {post.title}
            </Link>
          </h2>
          <p className="text-gray-600">{post.date}</p>
          <p className="mt-2">{post.excerpt}</p>
        </div>
      ))}
    </div>
  );
}
